import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getClientContextRoutingParams } from '../../features/clientContext';
import useClientContext from '../../features/clientContext/hooks/useClientContext';
import { useProfile } from '../../features/profile';
import { setAuthToken } from '../../utility/storage/Cookie';
import getQueryParams, {
  asString,
  buildUrlWithParams,
  redirectWithParams,
} from '../../utility/urlUtility';
import { removePermanentMessage, setPermanentMessage } from './slice';
import { asUrl, isValidSuccessUrl } from './successUrlHelpers';
import { useSecurity } from '../../features/security';

export type LoginContext = {
  successUrl: string;
};

export const useLoginContext = (): LoginContext | null => {
  const queryParams = getQueryParams() || {};
  const successUrl = asString(queryParams.success_url);
  if (successUrl !== '' && isValidSuccessUrl(asUrl(successUrl))) {
    return {
      successUrl,
    };
  }
  return null;
};

export type LoginSuccess = () => (loginToken: string) => void;

export const useLoginSuccess: LoginSuccess = () => {
  const clientContext = useClientContext();
  const loginContext = useLoginContext();
  const { loadProfile } = useProfile();
  const { loadSecurity } = useSecurity();
  let successUrl = '/';
  if (clientContext !== null) {
    successUrl = clientContext.successUrl;
  } else if (loginContext !== null) {
    successUrl = loginContext.successUrl;
  }

  return useCallback(
    async (loginToken: string): Promise<void> => {
      setAuthToken(loginToken);
      if (successUrl !== null) {
        redirectWithParams(successUrl, {});
      } else {
        await Promise.all([loadProfile(), loadSecurity()]);
      }
    },
    [successUrl, loadProfile, loadSecurity]
  );
};

export type LogoutSuccess = () => () => void;

export const useLogoutSuccess: LogoutSuccess = () => {
  return useCallback(() => setAuthToken(null), []);
};

export type ShowLoginParams = {
  permanentMessage?: string;
  setInternalSuccessUrl?: boolean;
};

export const useShowLogin = (): ((params?: ShowLoginParams) => void) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const clientContext = useClientContext();

  return useCallback(
    ({
      permanentMessage,
      setInternalSuccessUrl = true,
    }: ShowLoginParams = {}): void => {
      if (permanentMessage !== undefined) {
        dispatch(setPermanentMessage({ permanentMessage }));
      } else {
        dispatch(removePermanentMessage());
      }

      const loginQueryParams = {
        ...getClientContextRoutingParams(clientContext),
      };

      if (setInternalSuccessUrl) {
        loginQueryParams.success_url = document.location.href;
      }

      const loginUrl = buildUrlWithParams('/login', loginQueryParams);
      history.push(loginUrl);
    },
    // plain objects can not be used in the dependency array
    // eslint-disable-next-line
    [dispatch, history, JSON.stringify(clientContext)]
  );
};
