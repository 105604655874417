import React, { FC } from 'react';
import Translate from '../../components/translate';
import { H1 } from '../../components/typography';
import { useClientContext } from '../clientContext';
import { Action, ActionDictionary } from './types';

export interface ClientRedirectInfoProps {
  action: Action;
}

export const actionDictionary: ActionDictionary = {
  login: 'Log in',
  signUp: 'Sign up',
};

const ClientRedirectInfo: FC<ClientRedirectInfoProps> = ({
  action,
}: ClientRedirectInfoProps) => {
  const clientContext = useClientContext();
  const clientName = clientContext?.client.name;
  const mappedAction = actionDictionary[action];

  return (
    <H1 data-test="userinfo-header">
      {clientName ? (
        <Translate ns="user-info">
          {mappedAction} to continue to {{ clientName }}
        </Translate>
      ) : (
        <Translate ns="user-info">{mappedAction}</Translate>
      )}
    </H1>
  );
};

export default ClientRedirectInfo;
