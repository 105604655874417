import React, { FC, PropsWithChildren } from 'react';
import { PagePadding, TinyContainer } from './index';
import { AlignCenter } from '../typography';
import { Logo } from '../general';

type PageProps = PropsWithChildren<{
  header?: JSX.Element;
}>;

const Page: FC<PageProps> = ({ children, header }: PageProps) => {
  return (
    <PagePadding>
      <AlignCenter>
        <TinyContainer>
          <Logo />
          {header}
          {children}
        </TinyContainer>
      </AlignCenter>
    </PagePadding>
  );
};

export default Page;
