import React from 'react';
import styled from 'styled-components';
import { ColorVariant } from '../../../theme/default/colors';
import { SizeVariant } from '../../../theme/default/dimensions';
import FormRow from '../FormRow';

interface StyledButtonProps {
  color: ColorVariant;
  size: SizeVariant;
}

interface OptionalButtonProps {
  color?: ColorVariant;
  size?: SizeVariant;
}

const StyledButton = styled.button<StyledButtonProps>`
  // fixed styles (not part of the theme)
  display: inline-block;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  transition: all 0.05s ease-out 0s;
  font-weight: bold;
  position: relative;

  // base styles (part of the theme, but the same for each variant and state)
  padding: ${({ theme }): string => theme.button.base.padding};

  // size-dependent styles
  height: ${({ theme, size }): string => theme.button[size].normal.height};
  font-size: ${({ theme, size }): string => theme.button[size].normal.fontSize};
  line-height: ${({ theme, size }): string =>
    theme.button[size].normal.lineHeight};
  border-radius: ${({ theme, size }): string =>
    theme.button[size].normal.borderRadius};
  box-shadow: ${({ theme, size }): string =>
    theme.button[size].normal.boxShadow};

  // color-dependent styles
  color: ${({ theme, color }): string => theme.button[color].normal.color};
  border: 1px solid
    ${({ theme, color }): string => theme.button[color].normal.borderColor};
  background-color: ${({ theme, color }): string =>
    theme.button[color].normal.backgroundColor};

  &:hover {
    background-color: ${({ theme, color }): string =>
      theme.button[color].hover.backgroundColor};
    border-color: ${({ theme, color }): string =>
      theme.button[color].hover.borderColor};
    color: ${({ theme, color }): string => theme.button[color].hover.color};
  }

  &:active {
    color: ${({ theme, color }): string => theme.button[color].active.color};
    background-color: ${({ theme, color }): string =>
      theme.button[color].active.backgroundColor};
    border-color: ${({ theme, color }): string =>
      theme.button[color].active.borderColor};
  }

  &:disabled,
  &[disabled],
  &[disabled='disabled'] {
    cursor: not-allowed;

    color: ${({ theme, color }): string => theme.button[color].disabled.color};
    box-shadow: ${({ theme, color }): string =>
      theme.button[color]?.disabled?.boxShadow};
    background-color: ${({ theme, color }): string =>
      theme.button[color].disabled.backgroundColor};
    border-color: ${({ theme, color }): string =>
      theme.button[color].disabled.borderColor};
  }

  &:focus {
    outline: none;
    box-shadow: ${({ theme, color }): string =>
      theme.button[color]?.focus?.boxShadow};
  }
`;

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  OptionalButtonProps;

const Button: React.FC<ButtonProps> = ({
  type = 'button',
  color = 'regular',
  size = 'regular',
  children,
  ...props
}: ButtonProps) => (
  <StyledButton color={color} size={size} type={type} {...props}>
    {children}
  </StyledButton>
);

export const FullWidthButton = styled(Button)`
  width: 100%;
  font-size: 18px;
  font-family: inherit;
  height: 48px;
`;

export const ButtonRow = styled(FormRow)`
  margin-bottom: 0;
  text-align: right;
  margin-left: -5px;
  margin-right: -5px;

  & ${StyledButton} {
    margin-left: 5px;
    margin-right: 5px;
  }
`;

export default Button;
