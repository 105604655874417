import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import Translate from '../translate';

export const Message = styled.p`
  border: 1px solid rgba(98, 98, 110, 0.25);
  border-radius: 3px;
  margin-bottom: 1em;
  background-color: #f2f3f5;
  font-size: 13px;
  padding: 8px 12px;
  position: relative;
`;

export const ErrorMessage = styled(Message)`
  border: 1px solid #ff1b1b;
  background: #fff1f1;
`;

export const WarningMessage = styled(Message)`
  border: 1px solid #d6a206;
  background: #fff3cd;
`;

type TranslatedMessageProps = PropsWithChildren<unknown>;

export const TranslatedMessage: FC<TranslatedMessageProps> = ({
  children,
}: TranslatedMessageProps) => (
  <Message data-test="message">
    <Translate>{children}</Translate>
  </Message>
);

export const TranslatedErrorMessage: FC<TranslatedMessageProps> = ({
  children,
}: TranslatedMessageProps) => (
  <ErrorMessage data-test="error-message">
    <Translate>{children}</Translate>
  </ErrorMessage>
);

export interface TranslatedErrorMessagesProps {
  messages: string[] | null | undefined;
}

export const TranslatedErrorMessages: FC<TranslatedErrorMessagesProps> = ({
  messages,
}: TranslatedErrorMessagesProps) => (
  <>
    {(messages || []).map((message) => (
      <TranslatedErrorMessage key={message}>{message}</TranslatedErrorMessage>
    ))}
  </>
);

export default Message;
