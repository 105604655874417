import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientDetail } from './ClientContext';

export interface ClientContextState {
  clientDetails: Record<string, ClientDetail>;
}

const initialState: ClientContextState = {
  clientDetails: {},
};

export interface SetClientDetailsPayload {
  clientDetail: ClientDetail;
}

const slice = createSlice({
  name: 'clientContext',
  initialState,
  reducers: {
    setClientDetails(
      state,
      { payload: { clientDetail } }: PayloadAction<SetClientDetailsPayload>
    ): void {
      state.clientDetails[clientDetail.id] = clientDetail;
    },
  },
});

export const { setClientDetails } = slice.actions;

export default slice.reducer;
