import { ApiCall, apiPost, authHeader } from '../api';

export interface ValidateClientUrlParams {
  redirectUrl: string;
  clientId: string;
}

export type ValidateClientUrlResult = null;

export const validateClientUrl: ApiCall<
  ValidateClientUrlResult,
  ValidateClientUrlParams
> = ({ clientId, redirectUrl }) => {
  return apiPost<ValidateClientUrlResult>(
    `client/by-client-id/${clientId}/validate-url`,
    { redirectUrl },
    authHeader()
  );
};
