import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { ReactComponent as Graphic } from '../../assets/images/logo.svg';

const LogoWrapper = styled.span`
  fill: ${({ theme }): string => theme.colors.text.primary};
  display: inline-block;
  margin: 10px 0;
`;

const Logo = (props: ComponentProps<typeof LogoWrapper>): JSX.Element => {
  return (
    <LogoWrapper {...props} title="Camunda">
      <Graphic />
    </LogoWrapper>
  );
};

export default Logo;
