export const namedErrorsReducer = (
  result: Record<string, string[]>,
  current: string
): Record<string, string[]> => {
  const name = current.split('.')[0];
  return {
    ...result,
    [name]: [...(result[name] || []), current],
  };
};

export const mapErrorsByName = (errors: string[]): Record<string, string[]> =>
  (errors || []).reduce(namedErrorsReducer, {});
