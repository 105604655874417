import React, { FC, ReactNode } from 'react';
import { SocialLoginProvider } from '../../configuration/socialLogin';
import { useTranslate } from '../../utility/localization';
import { Action, ActionDictionary } from '../userInfo/types';
import {
  GoogleLoginIcon,
  LinkedInLoginIcon,
  SocialButtonElement,
  SocialLoginButtonIcon,
} from './components';

const actionDictionary: ActionDictionary = {
  login: 'Login with',
  signUp: 'Sign Up with',
};

const providerDictionary: Record<string, ReactNode> = {
  google: <GoogleLoginIcon />,
  linkedin: <LinkedInLoginIcon />,
};

export type SocialButtonProps = {
  provider: SocialLoginProvider;
  action: Action;
  onClick: () => unknown;
};

const SocialLoginButton: FC<SocialButtonProps> = ({
  provider: { id, name },
  action,
  onClick,
}: SocialButtonProps) => {
  const t = useTranslate();
  const text = t(`${actionDictionary[action]} {{ name }}`, { name });
  const icon = providerDictionary[id] ?? null;

  return (
    <SocialButtonElement
      type="button"
      size="small"
      title={text}
      onClick={onClick}
    >
      <SocialLoginButtonIcon>{icon}</SocialLoginButtonIcon>
      {text}
    </SocialButtonElement>
  );
};

export default SocialLoginButton;
