import React, { FC } from 'react';
import styled from 'styled-components';
import {
  ButtonProps,
  Form,
  FullWidthButton,
  FullWidthInputField,
  InputProps,
  TranslatedErrorMessage,
} from '../../components/form';
import Translate from '../../components/translate';
import RouterLink from '../router';

export const SignUpFormElement = styled(Form)`
  text-align: left;
  margin: 0 auto;
  position: relative;
`;

export const EmailField: FC<InputProps> = ({ title, ...props }: InputProps) => (
  <FullWidthInputField {...props} placeholder={title} title={title} />
);

export const FullNameField: FC<InputProps> = ({
  title,
  ...props
}: InputProps) => (
  <FullWidthInputField {...props} placeholder={title} title={title} />
);

export interface FieldErrorsProps {
  errors?: string[];
}

export const FieldErrors: FC<FieldErrorsProps> = ({
  errors,
}: FieldErrorsProps) => (
  <>
    {errors &&
      errors.map((error) => (
        <TranslatedErrorMessage key={error}>{error}</TranslatedErrorMessage>
      ))}
  </>
);

export const SignUpButton: FC<ButtonProps> = ({
  children,
  ...props
}: ButtonProps) => (
  <FullWidthButton {...props} color="primary">
    {children}
  </FullWidthButton>
);

const LoginLink: FC = () => (
  <RouterLink to="/login" data-test="login-link">
    <Translate>Login</Translate>
  </RouterLink>
);

export const LoginMessage: FC = () => (
  <div>
    <Translate>Already got an account?</Translate> <LoginLink />
  </div>
);
