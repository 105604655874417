import React, { FC, useState } from 'react';
import { Page } from '../../components/layout';
import SignUpForm from '../../features/signUp/SignUpForm';
import ClientRedirectInfo from '../../features/userInfo/ClientRedirectInfo';
import { signUp } from '../../utility/api/auth';
import { useApiCall } from '../../utility/api/hooks';
import { useLoginSuccess } from '../login/hooks';

export const SignUp: FC = () => {
  const onLoginSuccess = useLoginSuccess();
  const [signUpCall, { errors, loading }] = useApiCall(signUp);
  const [success, setSuccess] = useState(false);

  const onRegistrationSuccess = (token: string): void => {
    setSuccess(true);
    onLoginSuccess(token);
  };

  return (
    <Page header={<ClientRedirectInfo action="signUp" />}>
      <SignUpForm
        errors={errors}
        loading={loading || success}
        onSuccess={onRegistrationSuccess}
        signUpCall={signUpCall}
      />
    </Page>
  );
};

export default SignUp;
