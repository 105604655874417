import { getAuthToken } from '../storage/Cookie';
import apiRequest, { ApiMethod, ApiPromise } from './apiRequest';

export type ApiCall<R, P = undefined> = P extends undefined
  ? (params?: P, headers?: Record<string, string>) => ApiPromise<R>
  : (params: P, headers?: Record<string, string>) => ApiPromise<R>;

export type ApiErrorMapping = Record<number, string>;

const apiRequestWrapper: (
  method: ApiMethod
) => <R, P = Record<string, unknown>>(
  url: string,
  params?: P,
  headers?: Record<string, string>
) => ApiPromise<R> = (method: ApiMethod) => <R, P>(
  url: string,
  params?: P,
  headers?: Record<string, string>
): ApiPromise<R> => apiRequest(url, method, params, headers);

export const apiGet = apiRequestWrapper('GET');

export const apiPost = apiRequestWrapper('POST');

export const apiPut = apiRequestWrapper('PUT');

export const apiDelete = apiRequestWrapper('DELETE');

export type AuthHeader = {
  Authorization: string;
};

export const authHeader = (): AuthHeader => ({
  Authorization: `Bearer ${getAuthToken()}`,
});

/**
 * Helper function to handle error codes in api requests
 * Example: `const loadUser = (id: string) => mapErrors({ 404: 'user.not_found' }, apiGet('/user/${id}'));`
 * mapping is a dictionary with error codes as key and error message as value.
 * The error message will be added to errors array from the api response.
 * @param mapping
 * @param promise
 */
export const mapApiErrors: <R>(
  mapping: ApiErrorMapping,
  promise: ApiPromise<R>
) => ApiPromise<R> = async <R>(
  mapping: ApiErrorMapping,
  promise: ApiPromise<R>
) => {
  const result = await promise;
  const { status, errors } = result;
  if (status in mapping) {
    return {
      ...result,
      errors: [...(errors || []), mapping[status]],
    };
  }
  return result;
};
