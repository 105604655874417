/* eslint-disable camelcase */
import { buildUrlWithParams } from '../../urlUtility';
import { ApiCall, apiGet, apiPost, authHeader, mapApiErrors } from '../api';
import { ApiPromise } from '../apiRequest';

export type SignUpParams = {
  email: string;
  fullName: string;
  password: string;
  clientId?: string;
  successUrl?: string;
};

export type SignUpResult = {
  token: string;
};

export type SignUpWithInvitationRequestParams = {
  fullName: string;
  password: string;
  clientId?: string;
  successUrl?: string;
};
export type SignUpWithInvitationParams = SignUpWithInvitationRequestParams & {
  invitationToken: string;
};

export type SignUpWithInvitationResult = SignUpResult;

const signUpErrorMapping = {
  503: 'An error occurred. Please try again later.',
};

const signUpBase: <R>(
  url: string,
  params: SignUpParams | SignUpWithInvitationRequestParams
) => ApiPromise<R> = (
  url,
  { clientId: client_id, successUrl: success_url, ...params }
) =>
  mapApiErrors(
    signUpErrorMapping,
    apiPost(buildUrlWithParams(url, { client_id, success_url }), params)
  );

export const signUp: ApiCall<SignUpResult, SignUpParams> = (params) =>
  signUpBase<SignUpResult>('/sign-up', params);

export const signUpWithInvitation: ApiCall<
  SignUpWithInvitationResult,
  SignUpWithInvitationParams
> = ({ invitationToken, ...params }) =>
  signUpBase<SignUpWithInvitationResult>(
    `/sign-up-with-invitation/${encodeURIComponent(invitationToken)}`,
    params
  );

export type ValidateInvitationTokenParams = {
  invitationToken: string;
};

export type ValidateInvitationTokenResponse = {
  email: string;
};

export const validateInvitationToken: ApiCall<
  ValidateInvitationTokenResponse,
  ValidateInvitationTokenParams
> = ({ invitationToken }) =>
  apiGet(`/sign-up-with-invitation/${encodeURIComponent(invitationToken)}`);

export type VerifyParams = {
  hash: string;
};

export type VerifyResult = {
  token: string;
};

const verifyErrorMapping = {
  404: 'hash.invalid',
};

export const verify: ApiCall<VerifyResult, VerifyParams> = ({ hash }) =>
  mapApiErrors(verifyErrorMapping, apiPost(`/sign-up/${hash}/verify`));

export type ResendVerificationParams = {
  clientId?: string;
  successUrl?: string;
};

export type ResendVerificationResult = null;

export const resendVerification: ApiCall<
  ResendVerificationResult,
  ResendVerificationParams
> = ({ clientId: client_id, successUrl: success_url } = {}) =>
  apiPost(
    buildUrlWithParams('/sign-up-resend', { client_id, success_url }),
    undefined,
    authHeader()
  );
