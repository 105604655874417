import { darken, lighten } from 'polished';
import { ColorPalette, Dimensions, InputTheme } from '../types';

const inputThemeWithVariables = (
  colors: ColorPalette,
  dimensions: Dimensions,
  overrides?: Record<string, string>
): InputTheme => ({
  background: colors.white,
  borderColor: colors.border,
  borderRadius: '3px',
  fontSize: '1em',
  margin: '0',
  padding: '13px',
  textColor: colors.controlElements,
  placeholderColor: lighten(0.2, colors.controlElements),
  disabledColor: lighten(0.2, colors.controlElements),
  disabledBackground: darken(0.05, colors.background),
  disabledBorderColor: colors.border,
  focusBorderColor: '#3393ff',
  ...overrides,
});

export default inputThemeWithVariables;
