import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import reducer from './store/reducers';

const middleware = getDefaultMiddleware();

const store = configureStore({ reducer, middleware });

export type RootState = ReturnType<typeof reducer>;

export default store;
