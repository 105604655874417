import React from 'react';

export type ClientDetail = {
  id: string;
  name: string;
};

export type ClientContextDetail = {
  client: ClientDetail;
  successUrl: string;
};

const ClientContext = React.createContext<ClientContextDetail | null>(null);

export const ClientContextProvider = ClientContext.Provider;

export default ClientContext;
