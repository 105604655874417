import { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import {
  ldap,
  selfSignUp,
  socialLogin,
  userInvitation,
  verificationEmail,
} from '../configuration/features';
import Login from '../pages/login';
import SignUp from '../pages/sign-up';
import SignUpWithInvitation from '../pages/sign-up/with-invitation';

export type RouteConfiguration = {
  link: {
    to: string;
    title: string | JSX.Element;
  };
  route: RouteProps;
};

const routes: RouteConfiguration[] = [
  {
    link: { title: 'Login', to: '/login' },
    route: {
      component: Login,
      path: '/login',
      exact: true,
    },
  },
  ...(selfSignUp
    ? [
        {
          link: { title: 'Sign Up', to: '/sign-up' },
          route: {
            component: SignUp,
            path: '/sign-up',
            exact: true,
          },
        },
      ]
    : []),
  ...(userInvitation
    ? [
        {
          link: { title: 'Sign Up', to: '/sign-up/some-token' },
          route: {
            component: SignUpWithInvitation,
            path: '/sign-up/:invitationToken',
            exact: true,
          },
        },
      ]
    : []),
  ...(verificationEmail
    ? [
        {
          link: { title: 'Sign Up Verify', to: '/verify/wronghash' },
          route: {
            component: lazy(() => import('../pages/verify')),
            path: '/verify/:hash',
          },
        },
        {
          link: { title: 'Resend Email Verification', to: '/sign-up-resend' },
          route: {
            component: lazy(() => import('../pages/sign-up-resend')),
            path: '/sign-up-resend',
          },
        },
      ]
    : []),
  ...(!ldap
    ? [
        {
          link: { title: 'Password reset', to: '/password-reset' },
          route: {
            component: lazy(() => import('../pages/password-reset')),
            path: '/password-reset/:code?',
          },
        },
        {
          link: { title: 'Profile verify', to: '/profile/email-verify/:code' },
          route: {
            component: lazy(() => import('../pages/profile/email-verify')),
            path: '/profile/email-verify/:code',
            exact: true,
          },
        },
      ]
    : []),
  {
    link: { title: 'Profile', to: '/profile' },
    route: {
      component: lazy(() => import('../pages/profile')),
      path: '/profile',
      exact: true,
    },
  },
  {
    link: { title: 'Logout', to: '/logout' },
    route: {
      component: lazy(() => import('../pages/logout')),
      path: '/logout',
    },
  },
  {
    link: { title: 'Logout Comeback', to: '/logout-comeback' },
    route: {
      component: lazy(() => import('../pages/logout-comeback')),
      path: '/logout-comeback',
    },
  },
  ...(socialLogin
    ? [
        {
          link: {
            title: 'Social Login Callback',
            to: '/social-login/:id/callback',
          },
          route: {
            component: lazy(() => import('../pages/social-login')),
            path: '/social-login/:providerId/callback',
          },
        },
      ]
    : []),
];

export default routes;
