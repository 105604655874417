import { TFunction } from 'i18next';
import React, { ComponentType, useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const LocalizationContext = React.createContext('');

export const NamespaceProvider = LocalizationContext.Provider;

export function withNamespace<P>(
  namespace: string,
  Component: ComponentType<P>
): ComponentType<P> {
  return (props: P): JSX.Element => {
    return (
      <NamespaceProvider value={namespace}>
        <Component {...props} />
      </NamespaceProvider>
    );
  };
}

export function useTranslate(ns?: string): TFunction {
  const contextNs = useContext(LocalizationContext) || undefined;
  const { t } = useTranslation(ns || contextNs);
  return t;
}

export default NamespaceProvider;
