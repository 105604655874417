import React, { ChangeEvent, FC } from 'react';
import { FullWidthInputField } from '../../components/form';

export type PasswordFieldProps = {
  value: string;
  onChange(e: ChangeEvent<HTMLInputElement>): void | Promise<void>;
  disabled?: boolean;
  placeholder?: string;
  title?: string;
  autofocus?: boolean;
};

export const NewPasswordField: FC<PasswordFieldProps> = (
  props: PasswordFieldProps
) => (
  <FullWidthInputField
    {...props}
    type="password"
    data-test="new-password"
    name="password"
    id="password"
    autoComplete="new-password"
  />
);

export const CurrentPasswordField: FC<PasswordFieldProps> = (
  props: PasswordFieldProps
) => (
  <FullWidthInputField
    {...props}
    type="password"
    data-test="password"
    name="current-password"
    id="current-password"
    autoComplete="current-password"
  />
);
