import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SetPermanentMessagePayload {
  permanentMessage: string;
}

export interface LoginState {
  permanentMessage: string;
}

const initialState: LoginState = {
  permanentMessage: '',
};

const slice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setPermanentMessage(
      state,
      {
        payload: { permanentMessage },
      }: PayloadAction<SetPermanentMessagePayload>
    ): void {
      state.permanentMessage = permanentMessage;
    },
    removePermanentMessage(state): void {
      state.permanentMessage = '';
    },
  },
});

export const { setPermanentMessage, removePermanentMessage } = slice.actions;

export default slice.reducer;
