import { getEnvString } from './env';

export const appTitle = getEnvString('SITE_TITLE');

export const termsUrl = getEnvString('TERMS_URL');

export const privacyUrl = getEnvString('PRIVACY_URL');

export const noreplyEmailSender = getEnvString('EMAIL_NOREPLY_SENDER');

export const supportEmail = getEnvString('EMAIL_SUPPORT');

export const privacyEmail = getEnvString('EMAIL_PRIVACY') || supportEmail;
