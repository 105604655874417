interface AppWindow {
  env: Record<string, string>;
}

const { env } = (window as unknown) as AppWindow;

export type GetEnv<R = string | undefined> = (
  key: string,
  defaultValue?: R
) => R;

const getEnv = <R>(
  key: string,
  defaultValue: R,
  parser: (value: string) => R
): R => {
  const reactKey = `REACT_APP_${key}`;
  if (env && env[reactKey] !== undefined) {
    return parser(env[reactKey]);
  }
  if (process.env[reactKey] !== undefined) {
    return parser(process.env[reactKey] as string);
  }
  return defaultValue;
};

export const getEnvString: GetEnv<string> = (key, defaultValue = '') =>
  getEnv(key, defaultValue, (value) => value);

export const getEnvBoolean: GetEnv<boolean> = (key, defaultValue = false) =>
  getEnv(key, defaultValue, (value) => value === 'true');
