import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { loadProfile as loadProfileApiCall } from '../../utility/api/me/profile';
import { Profile, setProfile as setProfileAction } from './slice';

export type UseProfile = () => {
  profile: Profile | null;
  loadProfile: () => Promise<boolean>;
  loading: boolean;
  setProfile: (profile: Profile | null) => void;
};

export const useProfile: UseProfile = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const profile = useSelector((state: RootState) => state.profile.profile);

  const setProfile = useCallback(
    (newProfile: Profile | null): void => {
      dispatch(setProfileAction({ profile: newProfile }));
    },
    [dispatch]
  );

  const loadProfile = useCallback(async (): Promise<boolean> => {
    setLoading(true);

    const { data, success } = await loadProfileApiCall();

    if (success) {
      setProfile(data);
    } else {
      setProfile(null);
    }
    setLoading(false);

    return success;
  }, [setLoading, setProfile]);

  return { profile, loadProfile, loading, setProfile };
};
