import styled from 'styled-components';
import { FullWidthButton } from '../../components/form/Button';
import Row from '../../components/form/Row';

export { ReactComponent as GoogleLoginIcon } from '../../assets/images/google-icon.svg';
export { ReactComponent as LinkedInLoginIcon } from '../../assets/images/linkedin-icon.svg';

export const SocialButtonElement = styled(FullWidthButton)`
  text-align: center;
  background-color: transparent;
  font-size: 14px;
  padding: 0 30px 0 15px;
`;

export const SocialLoginButtonIcon = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  vertical-align: middle;
  float: left;

  & img,
  & svg {
    max-width: 20px;
    max-height: 20px;
    width: auto;
    height: auto;
  }
`;
export const SocialLoginButtonRow = styled(Row)`
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;
`;

export const SocialLoginButtonCell = styled.div`
  flex: 1 1;
  min-width: 50%;
  padding: 0 15px 15px 15px;

  @media (max-width: 600px) {
    min-width: 100%;
  }
`;
