import React, { ChangeEvent, FC, ReactNode } from 'react';
import styled from 'styled-components';
import {
  Divider,
  Form,
  FullWidthButton,
  FullWidthInputField,
} from '../../components/form';
import Translate from '../../components/translate';
import { AlignCenter, SecondaryText } from '../../components/typography';
import RouterLink from '../../features/router';
import { useTranslate } from '../../utility/localization';

type CredentialsHintProps = {
  client: string;
};

const StyledParagraph = styled.p`
  margin-bottom: 0.6em;
`;

export const CredentialsHint: FC<CredentialsHintProps> = ({
  client,
}: CredentialsHintProps) => (
  <StyledParagraph>
    <Translate>
      Hint: You can use your existing {{ client }} credentials.
    </Translate>
  </StyledParagraph>
);

type InputFieldProps = {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  autofocus?: boolean;
};

export const EMailInputField: FC<InputFieldProps> = ({
  autofocus = false,
  ...props
}: InputFieldProps) => {
  const translate = useTranslate('login');

  return (
    <>
      <FullWidthInputField
        {...props}
        autofocus={autofocus}
        title={translate('E-Mail')}
        placeholder={translate('Your email')}
        type="email"
        data-test="email"
        autoComplete="username"
        spellCheck="false"
        color="primary"
      />
    </>
  );
};

export const PasswordInputField: FC<InputFieldProps> = (
  props: InputFieldProps
) => {
  const translate = useTranslate('login');

  return (
    <FullWidthInputField
      {...props}
      type="password"
      data-test="password"
      title={translate('Password')}
      placeholder={translate('Your password')}
      autoComplete="current-password"
    />
  );
};

type SubmitButtonProps = {
  disabled?: boolean;
  children: ReactNode;
};

export const LoginButton: FC<SubmitButtonProps> = ({
  children,
  disabled = false,
}: SubmitButtonProps) => (
  <FullWidthButton
    disabled={disabled}
    type="submit"
    data-test="submit"
    color="primary"
    size="small"
  >
    {children}
  </FullWidthButton>
);

export const LoginFormElement = styled(Form)`
  text-align: left;
  position: relative;
`;

export const StyledDivider = styled(Divider)`
  margin: -12px 0;
`;

export const ForgottenPasswordLink: FC = () => (
  <RouterLink to="/password-reset" data-test="password-reset-link">
    <Translate>Forgot your password?</Translate>
  </RouterLink>
);

type MigrationHintProps = {
  client: string;
};

export const MigrationHint: FC<MigrationHintProps> = ({
  client,
}: MigrationHintProps) => (
  <AlignCenter>
    <SecondaryText>
      <Translate>
        To improve your experience, we changed the way you log in to{' '}
        {{ client }}. With this account, you will be able to log in to all
        Camunda products in the future.
      </Translate>
    </SecondaryText>
    <SecondaryText>
      <Translate>
        This does not affect your credentials and there are no extra steps to be
        taken from your side.
      </Translate>
    </SecondaryText>
    <SecondaryText>
      <Translate>Just log in as usual and get flowing.</Translate>
    </SecondaryText>
  </AlignCenter>
);
