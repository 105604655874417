import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Profile {
  uuid: string;
  fullName: string;
  email: string;
  version: number;
  verified: boolean;
}

export interface ProfileState {
  profile: Profile | null;
}

const initialState: ProfileState = {
  profile: null,
};

export interface SetProfilePayload {
  profile: Profile | null;
}

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile(
      state,
      { payload: { profile } }: PayloadAction<SetProfilePayload>
    ): void {
      state.profile = profile;
    },
  },
});

export const { setProfile } = slice.actions;

export default slice.reducer;
