import { ColorPalette, Theme, Dimensions } from '../types';
import buttonThemeWithVariables from './button';
import cardThemeWithVariables from './card';
import defaultColors from './colors';
import defaultDimensions from './dimensions';
import link from './link';
import inputThemeWithVariables from './input';
import typography from './typography';
import tooltip from './tooltip';

export const themeWithVariables = (
  colors: ColorPalette,
  dimensions: Dimensions
): Theme => ({
  colors,
  input: inputThemeWithVariables(colors, dimensions),
  button: buttonThemeWithVariables(colors, dimensions),
  card: cardThemeWithVariables(colors, dimensions),
  link,
  typography,
  tooltip,
});

const defaultTheme = themeWithVariables(defaultColors, defaultDimensions);

export type IamTheme = typeof defaultTheme;

export default defaultTheme;
