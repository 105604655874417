import styled from 'styled-components';

export const SimpleLink = styled.a``;

const Link = styled(SimpleLink)`
  &:visited {
    color: ${({ theme }): string => theme.link.visited.color};
  }
`;

export default Link;
