import queryString, { ParsedQuery } from 'query-string';

export const currentUrl = (): string => {
  return window?.document.location.href || '';
};

const getQueryParams = (query?: string): ParsedQuery | null => {
  if (query !== undefined) {
    return queryString.parse(query);
  }
  if (typeof window === 'undefined') {
    return null;
  }
  return queryString.parse(window.document.location.search);
};

export const asString = (param: unknown): string =>
  param !== undefined && param !== null ? `${param}` : '';

export const buildUrlWithParams = (
  urlString: string,
  params: Record<string, string | undefined>
): string => {
  const cleanedParams: Record<string, string> = {};
  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined) {
      cleanedParams[key] = params[key] as string;
    }
  });

  const { url, query } = queryString.parseUrl(urlString);
  const urlOptions = {
    url,
    query: {
      ...query,
      ...cleanedParams,
    },
  };
  return queryString.stringifyUrl(urlOptions);
};

export const redirectWithParams = (
  redirectUrl: string,
  params: Record<string, string> = {}
): void => {
  document.location.href = buildUrlWithParams(redirectUrl, params);
};

export default getQueryParams;
