import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const StyledTable = styled.table`
  width: 100%;
  margin-bottom: 1rem;
  border: 1px solid #c3c3c3;
  border-collapse: collapse;

  > tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  td,
  th {
    padding: 0.4rem 1rem;
    border-top: 1px solid #c3c3c3;
    border-left: 1px solid #c3c3c3;
  }

  th {
    text-align: left;
    white-space: nowrap;
  }
`;

const ScrollContainer: FC = styled.div`
  overflow: scroll;
`;

interface TableProps {
  scrollable?: boolean;
}

const Table: FC<PropsWithChildren<TableProps>> = ({
  scrollable = true,
  children,
}: PropsWithChildren<TableProps>) =>
  scrollable ? (
    <ScrollContainer>
      <StyledTable>{children}</StyledTable>
    </ScrollContainer>
  ) : (
    <StyledTable>{children}</StyledTable>
  );

export default Table;
