import React, { FC, PropsWithChildren, useContext } from 'react';
import { Trans, TransProps, useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../utility/localization';

type TranslateProps = PropsWithChildren<{
  ns?: string;
}> &
  TransProps<never>;

const Translate: FC<TranslateProps> = ({
  ns,
  children,
  ...transProps
}: TranslateProps) => {
  const contextNs = useContext(LocalizationContext) || undefined;
  const { t } = useTranslation(ns || contextNs);
  return (
    <Trans t={t} {...transProps}>
      {children}
    </Trans>
  );
};

export default Translate;
