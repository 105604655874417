import { ApiCall, apiGet, authHeader } from '../api';

export interface ClientLogoutInfo {
  clientId: string;
  logoutURL: string;
  logoutToken: string;
}

export type LogoutClientsResult = ClientLogoutInfo[];

export const logoutClients: ApiCall<LogoutClientsResult> = () =>
  apiGet<LogoutClientsResult>('/client/logout', undefined, authHeader());
