import { combineReducers } from 'redux';
import clientContext from '../../features/clientContext/slice';
import profile from '../../features/profile/slice';
import security from '../../features/security/slice';
import login from '../../pages/login/slice';

const reducer = combineReducers({
  login,
  profile,
  clientContext,
  security,
});

export default reducer;
