import React, { FC } from 'react';
import { ErrorMessage } from '../../components/form';
import Translate from '../../components/translate';
import NamespaceProvider, { useTranslate } from '../../utility/localization';

export interface PasswordErrorsProps {
  errors?: string[] | null;
}

export const PasswordErrors: FC<PasswordErrorsProps> = ({
  errors = [],
}: PasswordErrorsProps) => {
  const SIGN_UP_NS = 'sign-up';
  const t = useTranslate(SIGN_UP_NS);
  if (!errors?.length) return null;

  const translatedErrors = errors.map((error) => {
    const errorParts = error.split('.');
    const value = parseInt(errorParts.pop() as string, 10);
    const isSimpleError = Number.isNaN(value);
    if (isSimpleError) return t(error);
    return t(errorParts.join('.'), {
      count: value,
    });
  });
  const lastTranslatedError = translatedErrors.pop();

  return (
    <ErrorMessage>
      <NamespaceProvider value={SIGN_UP_NS}>
        <Translate>password.error.detailed</Translate>{' '}
        {translatedErrors.join(', ')}{' '}
        {translatedErrors.length > 0 ? (
          <Translate>password.error.detailed.and</Translate>
        ) : (
          ''
        )}{' '}
        {lastTranslatedError}
      </NamespaceProvider>
    </ErrorMessage>
  );
};
