import { useCallback, useState } from 'react';
import { ApiCall, ApiPromise } from '..';

export type UseApiCallPromise<R> = ApiPromise<R>;

export type UseApiCallFunction<R, P = undefined> = P extends undefined
  ? (params?: P) => UseApiCallPromise<R>
  : (params: P) => UseApiCallPromise<R>;

export type UseApiCallResult<R, P> = [
  UseApiCallFunction<R, P>,
  {
    data: R | null;
    loading: boolean;
    errors: string[] | null;
    status: number;
    success: boolean;
  }
];

/**
 * This hook is used to trigger an api call in an event handler.
 * The return parameters data, status and errors have the same value as the
 * promise result of the call function
 * @param {ApiCall} apiCall
 * @return UseApiCallResult
 */
export default function useApiCall<R, P>(
  apiCall: ApiCall<R, P>
): UseApiCallResult<R, P> {
  const [data, setData] = useState<R | null>(null);
  const [status, setStatus] = useState<number>(-1);
  const [errors, setErrors] = useState<string[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const call: UseApiCallFunction<R, P> = useCallback(
    async (params: P) => {
      setLoading(true);
      try {
        const {
          data: apiData,
          status: apiStatus,
          errors: apiErrors,
          success: apiSuccess,
        } = await apiCall(params);
        setData(apiData);
        setStatus(apiStatus);
        setErrors(apiErrors);
        setLoading(false);
        setSuccess(apiSuccess);
        return {
          data: apiData,
          status: apiStatus,
          errors: apiErrors,
          success: apiSuccess,
        };
      } catch (e) {
        setData(null);
        setStatus(-1);
        setErrors([e.message]);
        setLoading(false);
        setSuccess(false);
        return {
          data: null,
          status: -1,
          errors: [e.message],
          success: false,
        };
      }
    },
    [apiCall]
  ) as UseApiCallFunction<R, P>;

  return [
    call,
    {
      data,
      status,
      errors,
      loading,
      success,
    },
  ];
}
