import { useCallback, useEffect, useState } from 'react';
import { ApiCall } from '..';
import useApiCall, { UseApiCallPromise } from './useApiCall';

export interface UseApiResult<R> {
  data: R | null;
  loading: boolean;
  errors: string[] | null;
  status: number | null;
  success: boolean;
  reload(): UseApiCallPromise<R>;
}

export default function useApi<R, P>(
  apiCall: ApiCall<R, P>,
  params: P
): UseApiResult<R> {
  const [call, { data, status, errors, loading, success }] = useApiCall(
    apiCall
  );
  const [called, setCalled] = useState(false);

  const paramsDependency = JSON.stringify(params);
  // params are passed to dependencies as json string witch is not recognised by eslint
  // eslint-disable-next-line
  const reload = useCallback(() => call(params), [call, paramsDependency]);

  useEffect(() => {
    (async () => {
      await reload();
      setCalled(true);
    })();
  }, [reload, setCalled]);

  return {
    data,
    // set loading to true on initial call to prevent flickering
    loading: called ? loading : true,
    errors,
    status,
    reload,
    success,
  };
}
