/* eslint-disable camelcase */
import { backendUrl } from '../../../configuration/api';
import { SocialLoginProvider } from '../../../configuration/socialLogin';
import { buildUrlWithParams } from '../../urlUtility';

export type GenerateOauthAuthorizeUrl = (params: {
  clientId: string | null;
  redirectUrl: string | null;
  scope: string | null;
  state: string | null;
  responseType: string | null;
}) => string;

export const generateOauthAuthorizeUrl: GenerateOauthAuthorizeUrl = ({
  clientId,
  redirectUrl,
  scope,
  state,
  responseType,
}) =>
  buildUrlWithParams(`${backendUrl}/authorize`, {
    client_id: clientId ?? '',
    redirect_uri: redirectUrl ?? '',
    scope: scope ?? '',
    state: state ?? '',
    response_type: responseType ?? '',
  });

export type GenerateSocialLoginUrlParams = {
  provider: SocialLoginProvider;
  clientId?: string;
  successUrl?: string;
};

export type GenerateSocialLoginUrl = (
  params: GenerateSocialLoginUrlParams
) => string;

export const generateSocialLoginUrl: GenerateSocialLoginUrl = ({
  provider: { id },
  clientId,
  successUrl,
}) =>
  buildUrlWithParams(`${backendUrl}/social-login/${id}`, {
    client_id: clientId,
    success_url: successUrl,
  });
