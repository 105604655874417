import Cookies, { CookieSetOptions } from 'universal-cookie';

const AUTH_COOKIE_NAME = 'iam_auth';

const initCookie = (cookie?: Cookies): Cookies => cookie || new Cookies();

const cookieOptions: CookieSetOptions = {
  path: '/',
  sameSite: 'lax',
};

export const getCookieValue = (
  name: string,
  cookies?: Cookies
): string | null => {
  const cookie = initCookie(cookies);
  return cookie.get(name, { doNotParse: true }) || null;
};

export const setCookieValue = (
  name: string,
  value: string | null,
  cookies?: Cookies
): void => {
  const cookie = initCookie(cookies);

  if (value === null) {
    cookie.remove(name);
  } else {
    cookie.set(name, value, cookieOptions);
  }
};

export const getAuthToken = (cookies?: Cookies): string | null =>
  getCookieValue(AUTH_COOKIE_NAME, cookies);

export const setAuthToken = (
  authToken: string | null,
  cookies?: Cookies
): void => setCookieValue(AUTH_COOKIE_NAME, authToken, cookies);
