import { CardTheme, ColorPalette, Dimensions } from '../types';

const cardThemeWithVariables = (
  colors: ColorPalette,
  dimensions: Dimensions,
  overrides?: Record<string, string>
): CardTheme => ({
  background: colors.white,
  borderRadius: '3px',
  padding: '15px',
  ...overrides,
});

export default cardThemeWithVariables;
