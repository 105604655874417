import React, { FC } from 'react';
import styled from 'styled-components';
import Navigation from './Navigation';
import { Button } from '../components/form';
import { disableDevMode } from './helpers';

const Wrapper = styled.div`
  background-color: lightgrey;
  padding: 0 2em 1em;
`;

const Row = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2em;
  max-width: 200px;
`;

const DevBar: FC = () => {
  const handleButtonClick = (): void => {
    disableDevMode();
    window.location.reload();
  };

  return (
    <Wrapper>
      <Row>
        <Column>
          <p>You are currently viewing IAM in development mode.</p>
          <p>
            To disable it, click on the button below or delete dev-mode from
            local storage.
          </p>
          <Button onClick={handleButtonClick} size="small">
            Disable Dev Mode
          </Button>
        </Column>
        <Column>
          <Navigation />
        </Column>
      </Row>
    </Wrapper>
  );
};

export default DevBar;
