import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { RootState } from '../../../app/store';
import { generateOauthAuthorizeUrl } from '../../../utility/api/auth/helpers';
import { clientDetail, validateClientUrl } from '../../../utility/api/client';
import { ClientContextDetail } from '../ClientContext';
import { setClientDetails } from '../slice';
import { getClientContextQueryParams, isClientContextPassed } from '../utility';

export type UseApplyClientContextResult = {
  loading: boolean;
  error: boolean;
  context: ClientContextDetail | null;
};

export type UseApplyClientContext = () => UseApplyClientContextResult;

const useApplyClientContext: UseApplyClientContext = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const contextQueryParams = getClientContextQueryParams(location.search);
  const { clientId, successUrl, redirectUrl } = contextQueryParams;
  const isPassedFromQuery = isClientContextPassed(contextQueryParams);
  const isSuccessUrlUsed = successUrl !== '';
  const [isReturnUrlValidated, setIsReturnUrlValidated] = useState(false);
  const clientDetails = useSelector((state: RootState) =>
    clientId === null
      ? null
      : state.clientContext.clientDetails[clientId] || null
  );

  const clientLoading = isPassedFromQuery && clientDetails === null;
  const validationLoading =
    isPassedFromQuery && !isSuccessUrlUsed && !isReturnUrlValidated;
  const [error, setError] = useState<boolean>(false);
  const loading = clientLoading || validationLoading;

  let context: ClientContextDetail | null = null;
  if (isPassedFromQuery && clientDetails !== null) {
    if (isSuccessUrlUsed) {
      context = {
        client: clientDetails,
        successUrl,
      };
    } else if (isReturnUrlValidated) {
      context = {
        client: clientDetails,
        successUrl: generateOauthAuthorizeUrl(contextQueryParams),
      };
    }
  }

  useEffect(
    () => {
      const loadClientDetails = async () => {
        const { data, success } = await clientDetail({
          clientId,
        });
        if (success) {
          dispatch(
            setClientDetails({
              clientDetail: { id: clientId, name: data?.name || '' },
            })
          );
        } else {
          setError(true);
        }
      };

      const validateReturnUrl = async () => {
        setIsReturnUrlValidated(false);
        const { success } = await validateClientUrl({ clientId, redirectUrl });
        if (success) {
          setIsReturnUrlValidated(true);
        } else {
          setError(true);
        }
      };

      setError(false);
      if (isPassedFromQuery) {
        if (clientDetails === null) {
          loadClientDetails();
        }
        if (!isSuccessUrlUsed) {
          validateReturnUrl();
        }
      }
    },
    // eslint-disable-next-line
    [JSON.stringify(contextQueryParams)]
  );

  return {
    loading,
    error,
    context,
  };
};

export default useApplyClientContext;
