import React, { FC } from 'react';
import { Page } from '../../components/layout';
import ClientRedirectInfo from '../../features/userInfo/ClientRedirectInfo';
import { login } from '../../utility/api/auth';
import { useApiCall } from '../../utility/api/hooks';
import LoginForm from './LoginForm';

const Login: FC = () => {
  const [loginCall, { errors, loading, success }] = useApiCall(login);

  return (
    <Page header={<ClientRedirectInfo action="login" />}>
      <LoginForm
        errors={errors}
        loading={loading}
        success={success}
        loginCall={loginCall}
      />
    </Page>
  );
};

export default Login;
