import React, { FC, PropsWithChildren } from 'react';
import { Card, Logo } from '../general';
import { AlignCenter, AlignLeft } from '../typography';
import { ContentPadding, LargeContainer } from './index';

type ContentPageProps = PropsWithChildren<{
  header?: JSX.Element;
  withCard?: boolean;
}>;

const ContentPage: FC<ContentPageProps> = ({
  children,
  header,
  withCard = true,
}: ContentPageProps) => {
  return (
    <ContentPadding>
      <AlignCenter>
        <LargeContainer>
          <Logo />
          {header}
          {withCard ? (
            <Card>
              <AlignLeft>{children}</AlignLeft>
            </Card>
          ) : (
            <AlignLeft>{children}</AlignLeft>
          )}
        </LargeContainer>
      </AlignCenter>
    </ContentPadding>
  );
};

export default ContentPage;
