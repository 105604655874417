import styled from 'styled-components';

const Card = styled.div`
  background: ${({ theme }): string => theme.card.background};
  border: 1px solid ${({ theme }): string => theme.colors.border};
  padding: ${({ theme }): string => theme.card.padding};
  border-radius: ${({ theme }): string => theme.card.borderRadius};
`;

export default Card;
