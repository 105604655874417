/* eslint-disable camelcase */
import { Profile } from '../../../features/profile';
import { buildUrlWithParams } from '../../urlUtility';
import {
  ApiCall,
  apiGet,
  apiPost,
  apiPut,
  authHeader,
  mapApiErrors,
} from '../api';

export type ProfileResult = Profile;

export const loadProfile: ApiCall<ProfileResult> = () =>
  apiGet('/userinfo', undefined, authHeader());

export type UpdatePersonalResult = Profile;
export type UpdatePersonalParams = {
  fullName: string;
  version: number;
};

export const updateProfileErrorMapping = {
  409: 'version.conflict',
};

export const updatePersonal: ApiCall<
  UpdatePersonalResult,
  UpdatePersonalParams
> = (params) =>
  mapApiErrors(
    updateProfileErrorMapping,
    apiPut('/me/person', params, authHeader())
  );

export type UpdateEmailResult = Profile;
export type UpdateEmailParams = {
  password: string;
  email: string;
  clientId?: string;
  successUrl?: string;
};

export const updateEmail: ApiCall<UpdateEmailResult, UpdateEmailParams> = ({
  clientId: client_id,
  successUrl: success_url,
  ...params
}) =>
  apiPut(
    buildUrlWithParams('/me/email', { client_id, success_url }),
    params,
    authHeader()
  );

export type VerifyUpdateEmailResult = null;
export type VerifyUpdateEmailParams = {
  code: string;
};

export const verifyUpdateEmail: ApiCall<
  VerifyUpdateEmailResult,
  VerifyUpdateEmailParams
> = ({ code }) => apiPost(`/me/email/${code}/verify`);
