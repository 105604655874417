import React, { FC, ReactNode } from 'react';
import { Suspend } from '../../components/general';
import { Page } from '../../components/layout';
import Translate from '../../components/translate';
import { H1 } from '../../components/typography';
import { ClientContextProvider } from './ClientContext';
import useApplyClientContext from './hooks/useApplyClientContext';

type ClientContextWrapperProps = {
  children: ReactNode;
};

const ClientContextWrapper: FC<ClientContextWrapperProps> = ({
  children,
}: ClientContextWrapperProps) => {
  const { loading, error, context } = useApplyClientContext();

  if (loading && !error) return <Suspend />;

  if (error) {
    return (
      <Page
        header={
          <H1>
            <Translate>Error!</Translate>
          </H1>
        }
      >
        <p>
          <Translate>The link you have used is incorrect.</Translate>
        </p>
      </Page>
    );
  }

  return (
    <ClientContextProvider value={context}>{children}</ClientContextProvider>
  );
};

export default ClientContextWrapper;
