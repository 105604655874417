import { getEnvBoolean } from './env';

export const loginHint = getEnvBoolean('FEATURE_LOGIN_HINT');

export const ldap = getEnvBoolean('FEATURE_LDAP');

export const verificationEmail = ldap
  ? false
  : getEnvBoolean('FEATURE_VERIFICATION_EMAIL');

export const socialLogin = ldap ? false : getEnvBoolean('FEATURE_SOCIAL_LOGIN');

export const selfSignUp = ldap ? false : getEnvBoolean('FEATURE_SELF_SIGN_UP');

export const selfDelete = ldap ? false : getEnvBoolean('FEATURE_SELF_DELETE');

export const userInvitation = ldap
  ? false
  : getEnvBoolean('FEATURE_USER_INVITATION', true);
