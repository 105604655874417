import { ButtonTheme, ColorPalette, Dimensions } from '../types';
import defaultDimensions from './dimensions';

const buttonThemeWithVariables = (
  colors: ColorPalette,
  dimensions: Dimensions,
  overrides?: Record<string, string>
): ButtonTheme => {
  const baseTheme = {
    padding: '0 20px 0 20px',
  };

  const regularTheme = {
    normal: {
      boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.08)',
      borderRadius: '3px',
      fontSize: defaultDimensions.regular.fontSize,
      lineHeight: defaultDimensions.regular.lineHeight,
      backgroundColor: '#d8dce3',
      borderColor: '#b0bac7',
      color: 'rgba(69, 70, 78, 0.9)',
      height: defaultDimensions.regular.height,
    },
    hover: {
      backgroundColor: '#cdd4df',
      borderColor: '#9ea9b7',
      color: 'initial',
    },
    active: {
      backgroundColor: '#b0bac7',
      borderColor: '#88889a',
      color: 'rgba(49, 50, 56, 0.9)',
    },
    disabled: {
      boxShadow: 'none',
      backgroundColor: 'rgb(241, 242, 245)',
      borderColor: 'rgb(176, 186, 199)',
      color: 'rgba(69, 70, 78, 0.5)',
    },
    focus: {
      boxShadow: '0px 0px 0px 1px #2b7bff, 0px 0px 0px 4px #8cb7ff',
    },
  };

  const smallTheme = {
    normal: {
      fontSize: defaultDimensions.small.fontSize,
      lineHeight: defaultDimensions.small.lineHeight,
      borderRadius: regularTheme.normal.borderRadius,
      boxShadow: 'none',
      height: defaultDimensions.small.height,
    },
    hover: regularTheme.hover,
    active: regularTheme.active,
    disabled: regularTheme.disabled,
    focus: regularTheme.focus,
  };

  const primaryTheme = {
    normal: {
      backgroundColor: '#4d90ff',
      borderColor: '#3c85ff',
      color: '#fdfdfe',
      boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.35)',
    },
    hover: {
      backgroundColor: '#3c85ff',
      borderColor: '#1a70ff',
      color: '#fdfdfe',
    },
    active: {
      backgroundColor: '#1a70ff',
      borderColor: '#005df7',
      color: '#fdfdfe',
    },
    disabled: {
      backgroundColor: '#80b0ff',
      borderColor: '#80b0ff',
      color: '#fdfdfe',
      boxShadow: 'none',
    },
    focus: regularTheme.focus,
  };

  const secondaryTheme = {
    normal: {
      backgroundColor: '#f1f2f5',
      borderColor: '#b0bac7',
      color: 'rgba(69, 70, 78, 0.9)',
    },
    hover: {
      backgroundColor: '#e7e9ee',
      borderColor: '#b0bac7',
      color: 'rgba(69, 70, 78, 0.9)',
    },
    active: {
      backgroundColor: '#d3d6e0',
      borderColor: '#b0bac7',
      color: 'rgba(49, 50, 56, 0.9)',
    },
    disabled: {
      backgroundColor: '#f1f2f5',
      borderColor: '#b0bac7',
      color: 'rgba(69, 70, 78, 0.5)',
    },
    focus: regularTheme.focus,
  };

  const dangerTheme = {
    normal: {
      backgroundColor: '#ff3d3d',
      borderColor: '#ff0a0a',
      color: '#f8f8f8',
      boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.08)',
    },
    hover: {
      backgroundColor: '#ff0a0a',
      borderColor: '#d60000',
      color: '#f8f8f8',
    },
    active: {
      backgroundColor: '#d60000',
      borderColor: '#c20000',
      color: '#f8f8f8',
    },
    disabled: {
      backgroundColor: '#ffb3b3',
      borderColor: '#ffb3b3',
      color: '#f8f8f8',
      boxShadow: 'none',
    },
    focus: regularTheme.focus,
  };
  return {
    base: baseTheme,
    regular: regularTheme,
    small: smallTheme,
    primary: primaryTheme,
    secondary: secondaryTheme,
    danger: dangerTheme,
    ...overrides,
  };
};

export default buttonThemeWithVariables;
