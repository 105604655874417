import { getEnvString } from './env';

export type SocialLoginProvider = {
  id: string;
  name: string;
};

// Format for providers string is:
// id1:name1, id2:name2
// Example:
// google:Google, linkedin:LinkedIn

export const providers: Record<string, SocialLoginProvider> = getEnvString(
  'SOCIAL_LOGIN_PROVIDER'
)
  .split(',')
  .map((entry) => entry.trim().split(':', 2))
  .filter((parts) => parts.length > 1)
  .map(([id, name]) => [id.trim(), name.trim()])
  .reduce((result, [id, name]) => ({ ...result, [id]: { id, name } }), {});
