import { Dimensions } from '../types';

const defaultDimensions: Dimensions = {
  small: {
    fontSize: '13px',
    lineHeight: '20px',
    height: '22px',
  },
  regular: {
    fontSize: '14px',
    lineHeight: '36px',
    height: '38px',
  },
};

export type SizeVariant = 'regular' | 'small';

export default defaultDimensions;
