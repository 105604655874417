import React, { useCallback } from 'react';
import styled, { AnyStyledComponent } from 'styled-components';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  autofocus?: boolean;
}

export type InputWithAutofocus = {
  autofocus?: boolean;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type WithAutofocus = (
  Component: AnyStyledComponent
) => (props: InputWithAutofocus) => JSX.Element;

const withAutofocus: WithAutofocus = (Component: AnyStyledComponent) => ({
  autofocus = false,
  ...props
}): JSX.Element => {
  const callbackRef = useCallback(
    (element) => {
      if (autofocus && element !== null) {
        // React might call this callback before the element is actually mounted
        // in the DOM resulting in the element not being focused. This happens
        // when multiple HTML elements shall be rendered and can be bypassed
        // with a timeout
        window.setTimeout(() => {
          try {
            element.focus();
          } catch (e) {
            // element was probably unmounted
          }
        }, 100);
      }
    },
    [autofocus]
  );

  return <Component {...props} ref={callbackRef} />;
};

const StyledInputField = styled.input`
  font-family: inherit;
  outline: none;
  background: ${({ theme }): string => theme.input.background};
  border: 1px solid ${({ theme }): string => theme.input.borderColor};
  color: ${({ theme }): string => theme.input.textColor};
  border-radius: ${({ theme }): string => theme.input.borderRadius};
  font-size: ${({ theme }): string => theme.input.fontSize};
  padding: ${({ theme }): string => theme.input.padding};
  margin-top: 0px;
  transition: 0.5s color, 0.5s background, 0.5s border;
  height: 48px;

  &::placeholder {
    font-style: italic;
    color: ${({ theme }): string => theme.input.placeholderColor};
  }

  &[disabled],
  &:disabled {
    color: ${({ theme }): string => theme.input.disabledColor};
    background: ${({ theme }): string => theme.input.disabledBackground};
    border-color: ${({ theme }): string => theme.input.disabledBorderColor};
    user-select: none;
  }

  &:focus {
    border-color: ${({ theme }): string => theme.input.focusBorderColor};
  }
`;

export const FullWidthInputField = withAutofocus(styled(StyledInputField)`
  width: 100%;
`);

export const InputFieldPlaceholder = styled.span`
  font-family: inherit;
  display: inline-block;
  border-bottom: 1px solid ${({ theme }): string => theme.input.borderColor};
  border-left: 1px solid transparent;
  color: ${({ theme }): string => theme.input.textColor};
  font-size: ${({ theme }): string => theme.input.fontSize};
  padding: ${({ theme }): string => theme.input.padding};
  margin-top: 0px;
  height: 48px;
  line-height: 28px;
  text-overflow: ellipsis;
`;

export const FullWidthInputFieldPlaceholder = styled(InputFieldPlaceholder)`
  display: block;
`;

export default withAutofocus(StyledInputField);
