import { Typography, TypographySet } from '../types';

const typographyTheme = (
  fontSize: string,
  fontWeight: string,
  margin: string
): Typography => ({
  fontSize,
  fontWeight,
  margin,
});

const defaultFontSize = '15px';
const defaultTypographyMargin = '0.5em 0 1.2em 0';
const defaultHeaderWeight = '400';

const typography: TypographySet = {
  root: {
    fontSize: defaultFontSize,
    lineHeight: '1.5em',
    fontFamily: '"IBM Plex Sans", sans-serif',
  },
  p: {
    margin: '0 0 0.9em',
  },
  h1: typographyTheme('1.86em', defaultHeaderWeight, defaultTypographyMargin),
  h2: typographyTheme('1.66em', defaultHeaderWeight, defaultTypographyMargin),
  h3: typographyTheme('1.46em', defaultHeaderWeight, defaultTypographyMargin),
  h4: typographyTheme('1.26em', defaultHeaderWeight, defaultTypographyMargin),
  h5: typographyTheme('1em', defaultHeaderWeight, defaultTypographyMargin),
  subtitle1: typographyTheme('1.6em', '300', defaultTypographyMargin),
  subtitle2: typographyTheme('0.94em', '300', defaultTypographyMargin),
  body1: typographyTheme(defaultFontSize, '300', defaultTypographyMargin),
  body2: typographyTheme('0.94em', '300', defaultTypographyMargin),
};

export default typography;
