import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Security {
  hasPassword: boolean | null;
}

export type SecurityState = Security;

const initialState: SecurityState = { hasPassword: null };

export type SetSecurityPayload = Security | null;

const slice = createSlice({
  name: 'security',
  initialState,
  reducers: {
    setSecurity(
      state,
      { payload: security }: PayloadAction<SetSecurityPayload>
    ): void {
      state.hasPassword = security?.hasPassword || null;
    },
  },
});

export const { setSecurity } = slice.actions;

export default slice.reducer;
