import React, { FC } from 'react';
import { TextRow } from '../../components/form';
import { Link } from '../../components/general';
import Translate from '../../components/translate';
import { AlignCenter } from '../../components/typography';
import { privacyUrl, termsUrl } from '../../configuration/app';
import { Action, ActionDictionary } from './types';

interface ConsentNoticeProps {
  action: Action;
}

const actionDictionary: ActionDictionary = {
  login: 'logging in',
  signUp: 'signing up',
};

const ConsentNotice: FC<ConsentNoticeProps> = ({
  action,
}: ConsentNoticeProps) => {
  const mappedAction = actionDictionary[action];

  return (
    <TextRow>
      <AlignCenter>
        <Translate ns="user-info">
          By {mappedAction}, you agree to our&nbsp;
          {termsUrl && (
            <>
              <Link
                href={termsUrl}
                target="_blank"
                rel="noopener noreferrer"
                data-test="terms-link"
              >
                Terms of Use
              </Link>
              &nbsp;and&nbsp;
            </>
          )}
          <Link
            href={privacyUrl}
            target="_blank"
            rel="noopener noreferrer"
            data-test="privacy-link"
          >
            Privacy Policy
          </Link>
        </Translate>
      </AlignCenter>
    </TextRow>
  );
};

export default ConsentNotice;
