/* eslint-disable camelcase */
import { buildUrlWithParams } from '../../urlUtility';
import { ApiCall, apiPost } from '../api';

export interface PasswordValidateResult {
  valid: boolean;
}

export interface PasswordValidateParams {
  password: string;
}

export const passwordValidate: ApiCall<
  PasswordValidateResult,
  PasswordValidateParams
> = (params) => apiPost('/password-validate', params);

export type PasswordResetResult = null;

export interface PasswordResetParams {
  email: string;
  clientId?: string;
  successUrl?: string;
}

export const passwordReset: ApiCall<
  PasswordResetResult,
  PasswordResetParams
> = ({ clientId: client_id, successUrl: success_url, ...params }) =>
  apiPost(
    buildUrlWithParams('/password-reset', { client_id, success_url }),
    params
  );

export type PasswordResetUpdateResult = null;

export interface PasswordResetUpdateParams {
  code: string;
  password: string;
}

export const passwordResetUpdate: ApiCall<
  PasswordResetUpdateResult,
  PasswordResetUpdateParams
> = ({ code, ...params }) => apiPost(`/password-reset/${code}`, params);
