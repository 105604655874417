import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import { Security, setSecurity as setSecurityAction } from './index';
import { RootState } from '../../app/store';
import { internalSecurity as internalSecurityApiCall } from '../../utility/api/internal/security';

export type UseSecurity = () => {
  security: Security | null;
  loadSecurity: () => Promise<boolean>;
  loading: boolean;
  setSecurity: (security: Security | null) => void;
};

export const useSecurity: UseSecurity = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const security = useSelector((state: RootState) => state.security);

  const setSecurity = useCallback(
    (newSecurity: Security | null): void => {
      dispatch(setSecurityAction(newSecurity));
    },
    [dispatch]
  );

  const loadSecurity = useCallback(async (): Promise<boolean> => {
    setLoading(true);

    const { data, success } = await internalSecurityApiCall();

    if (success) {
      setSecurity(data);
    } else {
      setSecurity(null);
    }
    setLoading(false);

    return success;
  }, [setLoading, setSecurity]);

  return { security, loadSecurity, loading, setSecurity };
};
