import styled from 'styled-components';

const Container = styled.div`
  margin: 0 auto;
`;

export const TinyContainer = styled(Container)`
  max-width: 500px;
`;

export const MediumContainer = styled(Container)`
  max-width: 640px;
`;

export const LargeContainer = styled(Container)`
  max-width: 800px;
`;

export default Container;
