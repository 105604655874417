import axios, { AxiosRequestConfig } from 'axios';
import { backendUrl } from '../../configuration/api';

export type ApiResult<R> = {
  data: R | null;
  errors: string[] | null;
  status: number;
  success: boolean;
};

export type ApiPromise<R> = Promise<ApiResult<R>>;

export type ApiMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

const axiosRequest: <R>(config: AxiosRequestConfig) => ApiPromise<R> = async (
  config: AxiosRequestConfig
) => {
  try {
    const { data, status } = await axios(config);
    return { data, status, errors: null, success: true };
  } catch (e) {
    if (e.response === undefined) {
      // there is no response on a network error
      return { data: null, status: -1, errors: [], success: false };
    }
    const {
      response: { status, data },
    } = e;
    return {
      data: null,
      status,
      errors: data?.errors || [],
      success: false,
    };
  }
};

const apiRequest: <R, P = Record<string, unknown>>(
  url: string,
  method: ApiMethod,
  params?: P,
  headers?: Record<string, string>
) => ApiPromise<R> = async (url, method, params?, headers?) => {
  const paramsKey = ['PUT', 'POST', 'DELETE'].includes(method.toUpperCase())
    ? 'data'
    : 'params';
  return axiosRequest({
    url,
    baseURL: backendUrl,
    method,
    [paramsKey]: params,
    headers,
  });
};

export default apiRequest;
