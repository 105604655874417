import React, { FC, FormEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import {
  Footer,
  FormRow,
  Message,
  TranslatedErrorMessage,
} from '../../components/form';
import { Loading } from '../../components/general';
import Translate from '../../components/translate';
import {
  ldap,
  loginHint,
  selfSignUp,
  socialLogin,
} from '../../configuration/features';
import { useClientContext } from '../../features/clientContext';
import SocialLoginButtons from '../../features/socialLogin';
import { ConsentNotice } from '../../features/userInfo';
import { login } from '../../utility/api/auth';
import {
  CredentialsHint,
  EMailInputField,
  ForgottenPasswordLink,
  LoginButton,
  LoginFormElement,
  MigrationHint,
  PasswordInputField,
} from './components';
import { useLoginSuccess } from './hooks';

type LoginFormProps = {
  loginCall: typeof login;
  errors: string[] | null;
  loading: boolean;
  success?: boolean;
};

const LoginForm: FC<LoginFormProps> = ({
  loginCall,
  errors,
  loading,
  success = false,
}: LoginFormProps) => {
  const onLoginSuccess = useLoginSuccess();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const permanentMessage = useSelector<RootState, string>(
    (state) => state.login.permanentMessage
  );
  const clientContext = useClientContext();
  const clientName = clientContext?.client.name ?? 'Cawemo';
  const showFooter = selfSignUp || !ldap;

  const loginDisabled =
    username === '' || password === '' || loading || success;

  const inputDisabled = loading || success;

  const handleOnSubmit = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    if (!loginDisabled) {
      const { data } = await loginCall({
        username: username.trim(),
        password,
      });

      if (data && data.token) {
        onLoginSuccess(data.token);
      }
    }
  };

  return (
    <>
      {loginHint && <CredentialsHint client={clientName} />}
      <LoginFormElement onSubmit={handleOnSubmit}>
        {permanentMessage && <Message>{permanentMessage}</Message>}
        {errors &&
          errors.map((message) => (
            <TranslatedErrorMessage key={message}>
              {message}
            </TranslatedErrorMessage>
          ))}
        <FormRow>
          <EMailInputField
            autofocus
            value={username}
            disabled={inputDisabled}
            onChange={(e): void => setUsername(e.target.value)}
          />
        </FormRow>
        <FormRow>
          <PasswordInputField
            value={password}
            disabled={inputDisabled}
            onChange={(e): void => setPassword(e.target.value)}
          />
        </FormRow>
        <FormRow>
          <LoginButton disabled={loginDisabled}>
            {(loading || success) && <Loading color="white" />}
            <Translate>Login</Translate>
          </LoginButton>
        </FormRow>
        {showFooter && <Footer>{!ldap && <ForgottenPasswordLink />}</Footer>}
        {socialLogin && <SocialLoginButtons action="login" />}
        <ConsentNotice action="login" />
        {loginHint && <MigrationHint client={clientName} />}
      </LoginFormElement>
    </>
  );
};

export default LoginForm;
