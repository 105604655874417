import React, { FC } from 'react';
import styled from 'styled-components';
import { Page } from '../../components/layout';
import { H1 } from '../../components/typography';
import Translate from '../../components/translate';
import RouterLink from '../../features/router';
import { ReactComponent as Failmunda } from '../../assets/images/failmunda.svg';

const FailmundaWrapper = styled.span`
  display: block;
  margin: 10px 0;
`;

const NotFound: FC = () => {
  return (
    <Page
      header={<H1 data-test="failmunda-header">Failmunda - 404 Not Found</H1>}
    >
      <p>
        <Translate>
          What you&apos;re looking for isn&apos;t here, sorry!
        </Translate>
      </p>
      <FailmundaWrapper>
        <Failmunda />
      </FailmundaWrapper>
      <RouterLink to="/" data-test="home-link">
        ← <Translate>Click here to go back home</Translate>
      </RouterLink>
    </Page>
  );
};

export default NotFound;
