import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import routes from '../app/routes';
import { Link } from '../components/general';

const Navigation: FC = () => {
  return (
    <nav>
      <ul>
        {routes.map(({ link: { to, title } }) => (
          <li key={to}>
            <NavLink
              exact
              to={to}
              component={Link}
              activeStyle={{
                fontWeight: 'bold',
              }}
            >
              {title}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;
