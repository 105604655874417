import React, { ChangeEvent, FC, FormEvent, useState } from 'react';
import { Footer, FormRow } from '../../components/form';
import { Loading } from '../../components/general';
import Translate from '../../components/translate';
import { signUp } from '../../utility/api/auth';
import { mapErrorsByName } from '../../utility/form/errorHandling';
import { useTranslate } from '../../utility/localization';
import { useClientContext } from '../clientContext';
import {
  NewPasswordField,
  PasswordErrors,
  PasswordLoadingWrapper,
  usePasswordValidation,
} from '../passwordValidation';
import SocialLoginButtons from '../socialLogin';
import { ConsentNotice } from '../userInfo';
import {
  EmailField,
  FieldErrors,
  FullNameField,
  LoginMessage,
  SignUpButton,
  SignUpFormElement,
} from './components';
import { socialLogin } from '../../configuration/features';

type SignUpFormOnSuccess = (token: string) => void | Promise<void>;

type SignUpFormParams = {
  fixedEmail?: string;
  signUpCall: typeof signUp;
  errors: string[] | null;
  loading: boolean;
  onSuccess: SignUpFormOnSuccess;
};

export const SignUpForm: FC<SignUpFormParams> = ({
  fixedEmail,
  signUpCall,
  errors,
  loading,
  onSuccess,
}: SignUpFormParams) => {
  const t = useTranslate();
  const clientContext = useClientContext();
  const [email, setEmail] = useState(fixedEmail || '');
  const [fullName, setFullName] = useState('');
  const [password, setPassword] = useState('');
  const [
    onPasswordChange,
    {
      valid: passwordValid,
      errors: passwordValidationErrors,
      loading: passwordLoading,
    },
  ] = usePasswordValidation();

  const submitDisabled =
    loading ||
    !passwordValid ||
    [email, fullName, password].some((value) => value.trim() === '') ||
    passwordValidationErrors !== null;

  const namedErrors = mapErrorsByName(errors || []);

  const passwordErrors = passwordValidationErrors ?? namedErrors.password;

  const handlePasswordChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>): void => {
    setPassword(value);
    onPasswordChange(value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (!submitDisabled) {
      const clientParams =
        clientContext !== null
          ? {
              clientId: clientContext.client.id,
              successUrl: clientContext.successUrl,
            }
          : {};
      const { data, errors: callErrors } = await signUpCall({
        email,
        fullName,
        password,
        ...clientParams,
      });
      if (callErrors === null && data?.token) {
        onSuccess(data.token);
      }
    }
  };

  return (
    <>
      <SignUpFormElement onSubmit={handleSubmit}>
        <FormRow>
          <FullNameField
            autoFocus
            title={t('Your name')}
            data-test="name"
            disabled={loading}
            value={fullName}
            onChange={(e): void => setFullName(e.target.value)}
          />
          <FieldErrors errors={namedErrors.fullName} />
        </FormRow>
        <FormRow>
          <EmailField
            title={t('Your email address')}
            disabled={loading || !!fixedEmail}
            type="email"
            data-test="email"
            name="email"
            id="email"
            autoComplete="username"
            value={email}
            onChange={(e): void => setEmail(e.target.value)}
          />
          <FieldErrors errors={namedErrors.email} />
        </FormRow>
        <FormRow>
          <PasswordLoadingWrapper loading={passwordLoading}>
            <NewPasswordField
              title={t('Your password')}
              placeholder={t('Choose a password')}
              disabled={loading}
              value={password}
              onChange={handlePasswordChange}
            />
          </PasswordLoadingWrapper>
          <PasswordErrors errors={passwordErrors} />
        </FormRow>
        <FormRow>
          <SignUpButton
            type="submit"
            data-test="submit"
            disabled={submitDisabled}
          >
            {loading && <Loading color="white" />}
            <Translate>Sign Up</Translate>
          </SignUpButton>
        </FormRow>
        <Footer>
          <LoginMessage />
        </Footer>
        {socialLogin && <SocialLoginButtons action="signUp" />}
        <FormRow>
          <ConsentNotice action="signUp" />
        </FormRow>
      </SignUpFormElement>
    </>
  );
};

export default SignUpForm;
