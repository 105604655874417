import styled from 'styled-components';

export const TextRow = styled.div`
  margin: 32px 0;
`;

const Row = styled(TextRow)`
  display: flex;
  justify-content: space-between;

  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

export const CenterRow = styled(Row)`
  justify-content: center;
`;

export default Row;
