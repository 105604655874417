import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Loading } from '../../components/general';

const PasswordLoadingBlock = styled.div`
  position: relative;
`;

const PasswordLoadingOuter = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
  font-size: 70%;
`;

const PasswordLoading: FC = () => (
  <PasswordLoadingOuter>
    <Loading backgroundColor="transparent" />
  </PasswordLoadingOuter>
);

export interface PasswordLoadingWrapperParams {
  loading: boolean;
}

const PasswordLoadingWrapper: FC<
  PropsWithChildren<PasswordLoadingWrapperParams>
> = ({
  loading,
  children,
}: PropsWithChildren<PasswordLoadingWrapperParams>) => {
  return (
    <PasswordLoadingBlock>
      {children}
      {loading && <PasswordLoading />}
    </PasswordLoadingBlock>
  );
};

export default PasswordLoadingWrapper;
