/* eslint-disable global-require */
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    whitelist: ['en'],
    load: 'languageOnly',
    saveMissing: process.env.NODE_ENV === 'development', // send not translated keys to endpoint in development mode
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    partialBundledLanguages: true,
    resources: {
      en: {
        translation: require('../locales/en/translation.json'),
        login: require('../locales/en/login.json'),
        'social-login': require('../locales/en/social-login.json'),
        'user-info': require('../locales/en/user-info.json'),
        'sign-up': require('../locales/en/sign-up.json'),
        'not-found': require('../locales/en/not-found.json'),
      },
    },
  });

export default i18n;
