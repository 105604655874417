import { appUrl, backendUrl } from '../../configuration/api';
import { removeItem, setLocalItem } from '../../utility/storage/LocalStorage';

const LOGIN_SUCCESS_URL_LOCAL_KEY = 'login-success-url';

export const asUrl = (urlString: string): URL => {
  const absolute = /^(http:\/\/|https:\/\/)/.test(urlString);
  if (absolute) {
    return new URL(urlString);
  }
  const { protocol, host } = document.location;
  return new URL(urlString, `${protocol}//${host}`);
};

export const isValidSuccessUrl = (url: URL): boolean => {
  const host = url.host?.toLowerCase();
  const app = asUrl(appUrl);
  const backend = asUrl(backendUrl);

  if (!host) return false;

  return host === app.host || host === backend.host;
};

export const saveSuccessUrl = (url: string | null): void => {
  if (url && isValidSuccessUrl(asUrl(url as string)))
    setLocalItem(LOGIN_SUCCESS_URL_LOCAL_KEY, url);
  else removeItem(LOGIN_SUCCESS_URL_LOCAL_KEY);
};
