import React, { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { buildUrlWithParams } from '../../utility/urlUtility';
import { getClientContextRoutingParams } from '../clientContext';
import useClientContext from '../clientContext/hooks/useClientContext';

type RouterLinkProps = LinkProps & { applyClientContext?: boolean };

const RouterLink: FC<RouterLinkProps> = ({
  to,
  children,
  applyClientContext = true,
  ...props
}: RouterLinkProps) => {
  const urlWithContext = buildUrlWithParams(
    to as string,
    getClientContextRoutingParams(useClientContext())
  );

  return (
    <Link to={applyClientContext ? urlWithContext : to} {...props}>
      {children}
    </Link>
  );
};

export default RouterLink;
