export const setLocalItem = (key: string, value: string): void =>
  localStorage.setItem(key, value);

export const getLocalItem = (key: string): string | null =>
  localStorage.getItem(key);

export const popLocalItem = (key: string): string | null => {
  const result = localStorage.getItem(key);
  localStorage.removeItem(key);
  return result;
};

export const removeItem = (key: string): void => localStorage.removeItem(key);
