import { getLocalItem, removeItem } from '../utility/storage/LocalStorage';

export const DEV_MODE_KEY = 'dev-mode';

const getDevFlag = (): string | null => {
  return getLocalItem(DEV_MODE_KEY);
};

export const disableDevMode = (): void => {
  removeItem(DEV_MODE_KEY);
};

export const devModeEnabled = (): boolean => {
  return process.env.NODE_ENV !== 'production' && getDevFlag() === 'true';
};
