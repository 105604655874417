import React, { FC, PropsWithChildren } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Theme } from '../../theme';

const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
  body {
    color: ${({ theme }): string => theme.colors.text.primary};
    font-family: ${({ theme }): string => theme.typography.root.fontFamily};
    font-size: ${({ theme }): string => theme.typography.root.fontSize};
    line-height: ${({ theme }): string => theme.typography.root.lineHeight};
    background-color: ${({ theme }): string => theme.colors.background};;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }
  * {
    box-sizing: border-box;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const AppRootWrapper = styled.div`
  font-family: ${({ theme }): string => theme.typography.body1.fontFamily};
  font-size: ${({ theme }): string => theme.typography.body1.fontSize};
  line-height: ${({ theme }): string => theme.typography.body1.lineHeight};
  color: ${({ theme }): string => theme.colors.text.primary};
  position: relative;
  min-height: 100vh;

  & a {
    transition: 0.3s color;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }): string => theme.link.normal.color};

    &:hover {
      color: ${({ theme }): string => theme.link.hover.color};
    }

    &:active {
      color: ${({ theme }): string => theme.link.active.color};
    }
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const AppRoot: FC = ({ children }: PropsWithChildren<unknown>) => (
  <AppRootWrapper>
    <GlobalStyle />
    {children}
  </AppRootWrapper>
);

export default AppRoot;
