import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

const TooltipText = styled.span``;

// TODO: move tooltip elsewhere if not on the screen anymore (for desktop)

const TooltipBox = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;

  ${TooltipText} {
    visibility: hidden;
    position: absolute;
    width: 220px;
    background-color: ${({ theme }): string => theme.tooltip.backgroundColor};
    color: ${({ theme }): string => theme.tooltip.color};
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    top: -5px;
    right: 0;
    transform: translateX(calc(100% + 10px));
  }

  ${TooltipText}::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${({ theme }): string => theme.tooltip.borderColor};
  }

  &:hover {
    ${TooltipText} {
      visibility: visible;
      opacity: 1;
    }
  }
`;

interface TooltipProps {
  text: ReactNode;
  children: ReactNode;
}
const Tooltip: FC<TooltipProps> = ({ text, children }: TooltipProps) => (
  <TooltipBox>
    {children}
    <TooltipText>{text}</TooltipText>
  </TooltipBox>
);

export default Tooltip;
