import { ApiCall, apiGet } from '../api';

export interface ClientDetailResponse {
  name: string;
}

export interface ClientDetailParams {
  clientId: string;
}

export const clientDetail: ApiCall<
  ClientDetailResponse,
  ClientDetailParams
> = ({ clientId }) => apiGet(`/client/by-client-id/${clientId}`);
