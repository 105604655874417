import styled from 'styled-components';

export const AlignRight = styled.div`
  text-align: right;
`;

export const AlignLeft = styled.div`
  text-align: left;
`;

export const AlignCenter = styled.div`
  text-align: center;
`;
