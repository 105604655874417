import { ColorPalette } from '../types';

const defaultColors: ColorPalette = {
  text: {
    primary: '#62626e',
    secondary: '#8b8b8b',
  },
  controlElements: '#393a41',
  background: '#f6f6f6',
  white: '#fff',
  border: 'rgb(216, 220, 227)',
  primary: '#2b7bff',
  secondary: '#62626e',
};

export type ColorVariant = 'regular' | 'primary' | 'secondary' | 'danger';

export default defaultColors;
