import '@ibm/plex/css/ibm-plex.css';
import React, { FC, Suspense } from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Loading } from '../components/general';
import { AppRoot } from '../components/layout';
import DevBarContainer from '../development';
import ClientContextWrapper from '../features/clientContext/ClientContextWrapper';
import theme from '../theme/default';
import './i18next';
import routes from './routes';
import store from './store';
import NotFound from '../pages/not-found';

const App: FC = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <AppRoot>
        <Router>
          <DevBarContainer />
          <Switch>
            <Redirect path="/" to="/profile" exact />
            <Suspense fallback={<Loading />}>
              <ClientContextWrapper>
                <Switch>
                  {routes.map(({ route }) => (
                    <Route {...route} key={route.path as string} />
                  ))}
                  <Route component={NotFound} />
                </Switch>
              </ClientContextWrapper>
            </Suspense>
          </Switch>
        </Router>
      </AppRoot>
    </ThemeProvider>
  </Provider>
);

export default App;
