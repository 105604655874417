import styled from 'styled-components';

export const H1 = styled.h1`
  font-size: ${({ theme }): string => theme.typography.h1.fontSize};
  font-weight: ${({ theme }): string => theme.typography.h1.fontWeight};
  line-height: ${({ theme }): string => theme.typography.root.lineHeight};
  margin: ${({ theme }): string => theme.typography.h1.margin};
  color: ${({ theme }): string => theme.colors.text.primary};
`;

export const H2 = styled.h2`
  font-size: ${({ theme }): string => theme.typography.h2.fontSize};
  font-weight: ${({ theme }): string => theme.typography.h2.fontWeight};
  line-height: ${({ theme }): string => theme.typography.root.lineHeight};
  margin: ${({ theme }): string => theme.typography.h2.margin};
  color: ${({ theme }): string => theme.colors.text.primary};
`;

export const H3 = styled.h3`
  font-size: ${({ theme }): string => theme.typography.h3.fontSize};
  font-weight: ${({ theme }): string => theme.typography.h3.fontWeight};
  line-height: ${({ theme }): string => theme.typography.root.lineHeight};
  margin: ${({ theme }): string => theme.typography.h3.margin};
`;

export const H4 = styled.h4`
  font-size: ${({ theme }): string => theme.typography.h4.fontSize};
  font-weight: ${({ theme }): string => theme.typography.h4.fontWeight};
  line-height: ${({ theme }): string => theme.typography.root.lineHeight};
  margin: ${({ theme }): string => theme.typography.h4.margin};
`;

export const H5 = styled.h5`
  font-size: ${({ theme }): string => theme.typography.h5.fontSize};
  font-weight: ${({ theme }): string => theme.typography.h5.fontWeight};
  line-height: ${({ theme }): string => theme.typography.root.lineHeight};
  margin: ${({ theme }): string => theme.typography.h5.margin};
`;

export default H2;
