import React, { FC } from 'react';
import { devModeEnabled } from './helpers';

const DevBarContainer: FC = () => {
  let Container;

  if (devModeEnabled()) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
    Container = require('./DevBar').default;
  } else {
    Container = (): null => null;
  }

  return <Container />;
};

export default DevBarContainer;
