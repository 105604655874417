import { parseToRgb, rgba } from 'polished';
import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

const SpinnerKeyframe = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingOuter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: ${({ theme, color }): string =>
    color || rgba({ ...parseToRgb(theme.colors.background), alpha: 0.5 })};
`;

const Spinner = styled.div`
  border-radius: 50%;

  // intentionally sized based on the parent's font-size
  width: 2em;
  height: 2em;
  left: 50%;
  top: 50%;
  margin-left: -1em;
  margin-top: -1em;

  position: relative;

  border: 3px solid ${({ color }): string => color ?? '#333'};
  border-right-color: transparent;

  animation: ${SpinnerKeyframe} 0.65s infinite linear;
`;

export interface LoadingProps {
  color?: string;
  backgroundColor?: string;
}

const Loading: FC<LoadingProps> = ({
  color,
  backgroundColor,
}: LoadingProps) => (
  <LoadingOuter color={backgroundColor}>
    <Spinner color={color} />
  </LoadingOuter>
);

export default Loading;
