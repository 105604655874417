import React, { FC, useState } from 'react';
import { Loading } from '../../components/general';
import Translate from '../../components/translate';
import { providers as configuredProviders } from '../../configuration/socialLogin';
import { StyledDivider } from '../../pages/login/components';
import { generateSocialLoginUrl } from '../../utility/api/auth/helpers';
import { withNamespace } from '../../utility/localization';
import { useClientContext } from '../clientContext/hooks';
import { Action } from '../userInfo/types';
import { SocialLoginButtonCell, SocialLoginButtonRow } from './components';
import SocialLoginButton from './SocialLoginButton';

export type SocialLoginButtonsProps = {
  action: Action;
};

const SocialLoginButtons: FC<SocialLoginButtonsProps> = ({
  action,
}: SocialLoginButtonsProps) => {
  const [loading, setLoading] = useState(false);
  const context = useClientContext();
  const providers = Object.values(configuredProviders);

  if (providers.length === 0) return <StyledDivider />;

  return (
    <>
      <StyledDivider>
        <Translate>or</Translate>
      </StyledDivider>
      <SocialLoginButtonRow>
        {providers.map((provider) => (
          <SocialLoginButtonCell key={provider.id}>
            <SocialLoginButton
              provider={provider}
              action={action}
              onClick={() => {
                setLoading(true);
                document.location.href = generateSocialLoginUrl({
                  provider,
                  clientId: context?.client.id,
                  successUrl: context?.successUrl,
                });
              }}
            />
          </SocialLoginButtonCell>
        ))}
        {loading && <Loading />}
      </SocialLoginButtonRow>
    </>
  );
};

export default withNamespace('social-login', SocialLoginButtons);
