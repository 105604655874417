import { LinkTheme } from '../types';

const linkTheme: LinkTheme = {
  normal: {
    color: '#346ac4',
  },
  hover: {
    color: '#4b7ccf',
  },
  active: {
    color: '#29549c',
  },
  visited: {
    color: '#a846fe',
  },
};

export default linkTheme;
