/* eslint-disable camelcase */
import { asUrl, isValidSuccessUrl } from '../../pages/login/successUrlHelpers';
import getQueryParams, { asString } from '../../utility/urlUtility';
import { ClientContextDetail } from './ClientContext';

export type OAuthQueryParams = {
  redirectUrl: string;
  state: string;
  scope: string;
  responseType: string;
};

export type ClientContextQueryParams = {
  clientId: string;
  successUrl: string;
} & OAuthQueryParams;

export const getClientContextQueryParams = (
  query?: string
): ClientContextQueryParams => {
  const queryParams = getQueryParams(query) || {};
  const mayBeSuccessUrl = asString(queryParams.success_url);
  let successUrl = '';
  if (mayBeSuccessUrl !== '' && isValidSuccessUrl(asUrl(mayBeSuccessUrl))) {
    successUrl = mayBeSuccessUrl;
  }
  return {
    clientId: asString(queryParams.client_id),
    successUrl,
    redirectUrl: asString(queryParams.redirect_uri),
    state: asString(queryParams.state),
    scope: asString(queryParams.scope),
    responseType: asString(queryParams.response_type),
  };
};

export const isClientContextPassed = ({
  clientId,
  successUrl,
  redirectUrl,
  responseType,
}: ClientContextQueryParams): boolean => {
  if (clientId === '') return false;
  return (
    successUrl !== '' ||
    [redirectUrl, responseType].every((param) => param !== '')
  );
};

export const getClientContextRoutingParams = (
  context: ClientContextDetail | null
): Record<string, string> => {
  if (context === null) {
    return {};
  }
  return {
    client_id: context.client.id,
    success_url: context.successUrl,
  };
};

export const getClientContextApiParams = getClientContextRoutingParams;
