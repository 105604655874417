import React, { FC, useState } from 'react';
import { useParams } from 'react-router';
import { TranslatedErrorMessage } from '../../../components/form';
import { Suspend } from '../../../components/general';
import { Page } from '../../../components/layout';
import { LoginMessage } from '../../../features/signUp/components';
import SignUpForm from '../../../features/signUp/SignUpForm';
import ClientRedirectInfo from '../../../features/userInfo/ClientRedirectInfo';
import {
  signUpWithInvitation,
  SignUpWithInvitationRequestParams,
  validateInvitationToken,
} from '../../../utility/api/auth';
import { useApi, useApiCall } from '../../../utility/api/hooks';
import { useLoginSuccess } from '../../login/hooks';

type SignUpWithInvitationParams = {
  invitationToken: string;
};

export const SignUpWithInvitation: FC = () => {
  const { invitationToken } = useParams<SignUpWithInvitationParams>();
  const onLoginSuccess = useLoginSuccess();
  const [signUpCall, { errors, loading }] = useApiCall(signUpWithInvitation);
  const [success, setSuccess] = useState(false);
  const {
    data,
    errors: validationErrors,
    success: validationSuccess,
    loading: validationLoading,
  } = useApi(validateInvitationToken, { invitationToken });
  const signUpTokenErrors = (errors || []).filter(
    (error) => error.indexOf('token.') === 0
  );

  const signUpCallWrapper = (params: SignUpWithInvitationRequestParams) =>
    signUpCall({ invitationToken, ...params });

  const onRegistrationSuccess = (token: string): void => {
    setSuccess(true);
    onLoginSuccess(token);
  };

  if (validationLoading) {
    return <Suspend />;
  }

  let content = (
    <TranslatedErrorMessage>
      An error occurred. Please try again later.
    </TranslatedErrorMessage>
  );

  if (validationSuccess && data) {
    content = (
      <>
        {signUpTokenErrors.map((error) => (
          <TranslatedErrorMessage key={error}>{error}</TranslatedErrorMessage>
        ))}
        <SignUpForm
          fixedEmail={data.email}
          errors={errors}
          loading={loading || success}
          onSuccess={onRegistrationSuccess}
          signUpCall={signUpCallWrapper}
        />
      </>
    );
  }

  if (validationErrors) {
    const isDuplicate = validationErrors.some(
      (error) => error === 'email.duplicate'
    );

    if (isDuplicate) {
      content = (
        <>
          <TranslatedErrorMessage>email.duplicate</TranslatedErrorMessage>
          <LoginMessage />
        </>
      );
    } else {
      content = (
        <>
          {validationErrors.map((error) => (
            <TranslatedErrorMessage key={error}>{error}</TranslatedErrorMessage>
          ))}
        </>
      );
    }
  }

  return <Page header={<ClientRedirectInfo action="signUp" />}>{content}</Page>;
};

export default SignUpWithInvitation;
