import { ApiCall, apiPost, mapApiErrors } from '..';

export type LoginParams = {
  username: string;
  password: string;
};

export interface LoginResult {
  success: boolean;
  token: string;
}

const errorDictionary: Record<number, string> = {
  401: 'We could not find an account matching your login details.',
  404: 'An error occurred. Please try again.',
  503: 'An error occurred. Please try again later.',
};

export const login: ApiCall<LoginResult, LoginParams> = (params) => {
  return mapApiErrors(errorDictionary, apiPost<LoginResult>('/login', params));
};
