import styled from 'styled-components';

const P = styled.p`
  margin: ${({ theme }): string => theme.typography.p.margin};
`;

const SecondaryText = styled(P)`
  font-size: ${({ theme }): string => theme.typography.body2.fontSize};
  font-weight: ${({ theme }): string => theme.typography.body2.fontWeight};
  line-height: ${({ theme }): string => theme.typography.body2.lineHeight};
  margin: ${({ theme }): string => theme.typography.body2.margin};
  color: ${({ theme }): string => theme.colors.text.secondary};
`;

export default SecondaryText;
