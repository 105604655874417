import styled from 'styled-components';

const Label = styled.label`
  margin: 0;
  padding: 0;
  display: block;

  & > *:first-child {
    margin-top: 0.3em;
  }
`;

export default Label;
