import styled from 'styled-components';

export const Subtitle1 = styled.p`
  font-size: ${({ theme }): string => theme.typography.subtitle1.fontSize};
  font-weight: ${({ theme }): string => theme.typography.subtitle1.fontWeight};
  margin: ${({ theme }): string => theme.typography.subtitle1.margin};
  color: ${({ theme }): string => theme.colors.text.secondary};
`;

export const Subtitle2 = styled.p`
  font-size: ${({ theme }): string => theme.typography.subtitle2.fontSize};
  font-weight: ${({ theme }): string => theme.typography.subtitle2.fontWeight};
  margin: ${({ theme }): string => theme.typography.subtitle2.margin};
  color: ${({ theme }): string => theme.colors.text.secondary};
`;
