import { useEffect } from 'react';
import { passwordValidate } from '../../utility/api/auth/password';
import { useApiCall } from '../../utility/api/hooks';

const PASSWORD_TYPING_TIMEOUT_MS = 200;
let passwordTypingTimeout = 0;

export type UsePasswordValidationResult = [
  (password: string) => void,
  {
    valid: boolean;
    errors: string[] | null;
    loading: boolean;
  }
];

export type UsePasswordValidation = () => UsePasswordValidationResult;

export const usePasswordValidation: UsePasswordValidation = () => {
  const [validatePassword, { data, errors, loading }] = useApiCall(
    passwordValidate
  );

  useEffect(() => (): void => window.clearTimeout(passwordTypingTimeout), []);

  const onPasswordChange = (password: string): void => {
    window.clearTimeout(passwordTypingTimeout);

    passwordTypingTimeout = window.setTimeout(
      (passwordInput: string) => validatePassword({ password: passwordInput }),
      PASSWORD_TYPING_TIMEOUT_MS,
      password
    );
  };

  const valid = data?.valid === true && errors === null && !loading;

  return [
    onPasswordChange,
    {
      valid,
      errors,
      loading,
    },
  ];
};
