import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const DividerOuter = styled.div`
  min-height: 1px;
  position: relative;
  padding: 12px 24px;
  text-align: center;
`;

const DividerBorder = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #ccc;
`;

const DividerContent = styled.div`
  min-height: 1px;
  position: relative;
  background-color: #f7f8fa;
  padding: 0 8px;
  display: inline-block;
  font-weight: bold;
`;

const Divider: FC = ({ children, ...props }: PropsWithChildren<unknown>) => {
  return (
    // props spreading is ok in HOC elements
    // eslint-disable-next-line react/jsx-props-no-spreading
    <DividerOuter {...props}>
      <DividerBorder />
      {children && <DividerContent>{children}</DividerContent>}
    </DividerOuter>
  );
};

export default Divider;
